<template>
  <div id="home">
    <get-a-quote />
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row :class="$vuetify.breakpoint.mdAndDown ? 'pa-2' : 'mx-8 mt-4 px-8'">
      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4" class="pa-6">
        <v-sheet  rounded>
          <v-img :aspect-ratio="16/9" style="border-radius: 10px 10px 10px 10px;" src="/static/planting-seeds-DMXLG3K.jpg" alt="planting seeds"></v-img>
          <h2 class="my-3">Planting Seeds of Hope</h2>
          <p>Lasting change is truly possible, no matter your age or the areas of struggle you may be dealing with.  After 40 years of counseling experience, Doug has seen real change in countless challenging &amp; seemingly impossible situations. The Bible says nothing is impossible with God and he has been a witness to God’s amazing work in people’s lives.  Doug partners with you to identify your areas of struggle and work to set a plan in motion for you to experience true healing and growth in each of those areas.</p>
        </v-sheet>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4" class="pa-6">
        <v-sheet  rounded>
          <v-img :aspect-ratio="16/9" style="border-radius: 10px 10px 10px 10px;" src="/static/nurteringseeds.png" alt="maintaining seedlings"></v-img>
          <h2 class="my-3">Nurturing Long Lasting Change</h2>
          <p>Our goal is never to just do a “mental health bandaid”, but rather seek true change.  This is always the goal in all of the areas in which we serve, including counseling, ministry and consulting. The truth that real long lasting change is possible makes it our ultimate goal. Some changes can happen more quickly than others but it does still happen.</p>
        </v-sheet>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4" class="pa-6">
        <v-sheet  rounded>
          <v-img :aspect-ratio="16/9" style="border-radius: 10px 10px 10px 10px;" src="/static/roots-gets-support-woman-hands-watering.png" alt="watering a plant"></v-img>
          <h2 class="my-3">Providing Ongoing Support</h2>
          <p>This can include maintenance treatment, ongoing skill building and other specific needs you may experience in your growth journey.  We are here to help you for the “long haul” as needed.</p>
        </v-sheet>
      </v-col>
    </v-row>
</v-container>
    <ImageAccentBar />
    <v-container :fluid="$vuetify.breakpoint.lgAndDown">
      <v-row class="mt-4">
      <v-col cols="12">
        <h2 :style="$vuetify.breakpoint.mdAndUp ? 'font-size: 2em;' : 'font-size: 1.5em;'" color="primary" :class="$vuetify.breakpoint.mdAndUp ? 'ml-3 text-uppercase' : 'text-uppercase'">Some of what we do</h2>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4" class="pa-4">
        <v-sheet :class="$vuetify.breakpoint.mdAndUp ? 'pa-6' : ''">
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
          <h2 class="my-3">Counseling</h2>
          <p :class="$vuetify.breakpoint.mdAndUp ? '' : 'mb-0'">Doug works with a wide range of issues. He only works with adults, and does individual, couples and adult family counseling. He does Christian based counseling when so desired.</p>
          
            </v-col>
            <v-divider vertical></v-divider>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" :cols="$vuetify.breakpoint.mdAndUp ? 5 : 12" class="pa-0 mt-5 ml-3">
            <ul><strong>Individual Counseling</strong>
              <li class="ml-6">Anxiety</li>
              <li class="ml-6">Depression</li>
              <li class="ml-6">Trauma/abuse</li>
              </ul>
            <ul class="mt-3"><strong>Marriage Counseling</strong>
            <li class="ml-6">Communication</li>
              <li class="ml-6">Reconciliation</li>
              <li class="ml-6">Healing after affairs</li>
              </ul>
          </v-col>
          <v-col v-else cols="11" class="pt-0 ml-4">
            <v-row>
              <v-col cols="6" class="pr-0">
            <ul class="pl-0"><strong>Individual Counseling</strong>
              <li>Anxiety</li>
              <li>Depression</li>
              <li>Christian counseling</li>
              </ul>
              </v-col>
              <v-col cols="6" class="pl-0 pr-0">
            <ul class="pl-0"><strong>Marriage Counseling</strong>
            <li>Communication</li>
              <li>Reconciliation</li>
              <li>Healing after affairs</li>
              </ul>
               </v-col>
            </v-row>
          </v-col>
          </v-row>
          <v-btn color="primary" text class="pl-0" @click="loadpage('Counseling')">See More</v-btn>
        </v-sheet>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 11 : 4" class="pa-4">
        <v-sheet :class="$vuetify.breakpoint.mdAndUp ? 'pa-6' : ''">
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
          <h2 class="my-3">Ministry</h2>
          <p :class="$vuetify.breakpoint.mdAndUp ? '' : 'mb-0'">Having been involved in Christian ministry for over 40 years, areas of specialty include pastors, missionaries, Christian leaders, and working with Christian organizations</p>
          
            </v-col>
            <v-divider vertical></v-divider>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" :cols="$vuetify.breakpoint.mdAndUp ? 5 : 12" class="pa-0 mt-5 ml-3">
            <ul><strong>Missionaries</strong>
              <li class="ml-6">Training Mission Teams</li>
              <li class="ml-6">Candidate Assesments</li>
              <li class="ml-6">Reentry Process</li>
              </ul>
            <ul class="mt-3"><strong>Pastors</strong>
            <li class="ml-6">Burnout</li>
              <li class="ml-6">Marital Conflict</li>
              <li class="ml-6">Church Conflicts</li>
              </ul>
          </v-col>
          <v-col v-else cols="12" class="pt-0 ml-4">
             <v-row>
              <v-col cols="6" class="pr-0">
            <ul class="pl-0"><strong>Missionaries</strong>
              <li>Training Mission Teams</li>
              <li>Candidate Assesments</li>
              <li>Reentry Process</li>
              </ul>
              </v-col>
              <v-col cols="6" class="pl-0 pr-0">
            <ul class="pl-0"><strong>Pastors</strong>
            <li>Burnout</li>
              <li>Marital Conflict</li>
              <li>Church Conflicts</li>
              </ul>
              </v-col>
             </v-row>
          </v-col>
          </v-row>
          <v-btn color="primary" text class="pl-0" @click="loadpage('Ministry')">See More</v-btn>
        </v-sheet>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 11 : 4" class="pa-4">
        <v-sheet :class="$vuetify.breakpoint.mdAndUp ? 'pa-6' : ''">
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12" :class="$vuetify.breakpoint.mdAndUp ? 'mr-3' : ''">
          <h2 class="my-3">Consulting</h2>
          <p :class="$vuetify.breakpoint.mdAndUp ? '' : 'mb-0'">Doug &amp; Joyce provide a range of consulting services to both the professional and ministry communities.  The goal is to provide helpful, meaningful and professional training, psychoeducational skill building, and other services to strengthen your ministry and organizational effectiveness.</p>
          
            </v-col>
            <v-divider vertical></v-divider>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="5" class="pa-0 mt-5 ml-3">
            <ul><strong>Church Consulting</strong>
              <li class="ml-6">Mediation Services</li>
              <li class="ml-6">Burnout &amp; Sabbaticals</li>
              <li class="ml-6">Internal Conflicts</li>
              </ul>
            <ul class="mt-3"><strong>Missions</strong>
            <li class="ml-6">Candidate Assessment</li>
              <li class="ml-6">Effective Member Care</li>
              <li class="ml-6">Trauma Care</li>
              <li class="ml-6">Critical Incident Counseling </li>
              </ul>
          </v-col>
          <v-col v-else cols="12" class="pa-0 mt-5 ml-3">
            <v-row>
              <v-col cols="6" class="pr-0">
            <ul class="pl-0"><strong>Church Consulting</strong></ul>
              <li>Mediation Services</li>
              <li>Burnout &amp; Sabbaticals</li>
              <li>Internal Conflicts</li>
              </v-col>
              <v-col cols="5" class="pl-0 pr-0">
            <ul class="pl-0"><strong>Missions</strong></ul>
            <li>Candidate Assessment</li>
              <li>Effective Member Care</li>
              <li>Trauma Care</li>
              <li>Critical Incident Counseling </li>
              </v-col>
              </v-row>
          </v-col>
          </v-row>
          <v-btn color="primary" text class="pl-0" @click="loadpage('Consulting')">See More</v-btn>
        </v-sheet>
      </v-col>
    </v-row>
    </v-container>

      <!-- <DougAccentBar/> -->
    <AccentBar v-if="$vuetify.breakpoint.mdAndUp" />
    <ContactBar />
  </div>
</template>

<script>
  export default {
    metaInfo() {
        return {
            title: 'Feil Counseling and Consulting',
            titleTemplate: 'Home | Feil Counseling and Consulting',
            link: [
              { rel: 'canonical', href: 'https://feilcounselingandconsulting.com/' }
            ],
            htmlAttrs: {
              lang: 'en-US'
            },
            meta: [
              { charset: 'utf-8' },
              { name: 'description', content: 'Partnering with you for help and lasting growth' },
              {property: 'og:site_name', content: 'Feil Counseling and Consulting'},
              {property: 'og:image', content: '/static/feil_logo_vertical.png'},
              {property: 'twitter:image:src', content: '/static/feil_logo_vertical.png'}
            ]
        };
    },
    components: {
      DougAccentBar: () => import('@/components/DougAccentBar'),
      GetAQuote: () => import('@/components/GetAQuote'),
      Subscribe: () => import('@/components/Subscribe'),
      Heading: () => import('@/components/Heading'),
      ContactBar: () => import('@/components/ContactBar'),
      AccentBar: () => import('@/components/AccentBar'),
      ImageAccentBar: () => import('@/components/ImageAccent'),
    },

    data: function() {
      return {
      highlightedTestimonial: {
        blurb: 'There are few men that have impacted my life as deeply as you have done! Thank you for being there for me in my darkest hours and pointing me to Christ!',
        person: 'Josh',
        title: 'Pastor'
      },
      features: [
        {
          title: 'Award-winning architecture',
          text: 'Every achievement begins with a vision. It is built on a foundation of creative concepts and dreams to become something bigger and better. At AlphaConstruction we build with those who follow a vision, not a path.',
          src: '/static/feature1.png'
        },
        {
          title: 'Relentless pursuit of your vision',
          text: 'Our commitment to excellence and continous improvement, to building lasting relationships with our clients, and to the team members who create your vision, are the guiding principles that assure successful outcomes on every project we build.',
          src: '/static/feature2.png'
        },
        {
          title: 'Your vision - Our mission',
          text: 'To build an everlasting environment of trust with our clients and team members that will foster customer loyalty by delivering superior service and quality projects.',
          src: '/static/feature3.png'
        }
      ],
    }
    },  methods: {
        loadpage(page){
            this.$router.push({name: page})
        },
    }
  }
</script>
<style>
</style>
